$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height(),
            offsetDefault = 80;

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight(),
                offset = $(this).data("animation-offset") ?? offsetDefault;

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                if (typeof $(this).data('animation-oneway') == 'undefined') {
                    $(this).removeClass("animateIn--active");
                }

            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(".js-navbutton").children().toggleClass("active");
        $(".js-nav, .js-navoverlay").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
        $("body").toggleClass("freeze");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popUp
    // *
    // *
    $("[data-popup]").click(function(event){
        event.preventDefault();
        var id = $(this).data("popup");
        window.location.hash = id;
        openPopUp($("#" + id));
    });

    // open popup if exists
    if(window.location.hash) {
        var hash = window.location.hash;
        if (hash && hash != 'a' && $(hash).length && $(".js-popup" + hash).length) {
            openPopUp($(hash));
        }
    }

    // open popUp
    function openPopUp($object) {
        $(".js-popup").css("z-index", 0);
        $object.addClass("active").css("z-index", 300);
        var headerColor = $object.data("header-color");
        $(".js-header").removeClass("dark, header--landing").addClass(headerColor);
        $(".js-popup-close").addClass("active");
        $("html, body").addClass("freeze");
    }

    // close popUp
    $(".js-popup-close").click(function(event){
        event.preventDefault();
        window.location.hash = "a";
        $(".js-header").removeClass("dark, light");
        $(".js-popup, .js-popup-close").removeClass("active");
        $("html, body").removeClass("freeze");
    });



     // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderImage
    // *
    // *
    var $sliderImage = $(".js-sliderimage");

    if ($sliderImage.length) {
        $sliderImage.each(function () {
            $(this).not('.slick-initialized').slick({
                fade: false,
                dots: false,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
                centerMode: true,
                centerPadding: '20%',
                slidesToShow: 1,
                speed: 800,
                prevArrow: $(this).parents(".js-sliderimage-wrapper").find(".js-sliderimage-left"),
                nextArrow: $(this).parents(".js-sliderimage-wrapper").find(".js-sliderimage-right"),
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            // slidesToShow: 2,
                            // centerMode: false,
                            arrows: false,
                            centerMode: false
                            // centerPadding: '0%',
                        }
                    },
                ]
            });

            $(this).slick('slickGoTo',0);

            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $(".js-sliderimage-total").html(nextSlide + 1);
            });
        })
    }
});


$(window).on("load resize scroll", function() {
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * inverted items
    // *
    // *
    var invertItemPos = $(".js-invert-item").position().top;

    $(".js-invert-section").each(function() {
        var sectionStart = $(this).offset().top - $(window).scrollTop(),
            sectionEnd = $(this).offset().top + $(this).outerHeight() - $(window).scrollTop();

        if ((invertItemPos + 1) > sectionStart && (invertItemPos) < sectionEnd) {
            //console.log("inverted");
            $(".js-invert-item").addClass("inverted");
        } else {
            //console.log("not inverted");
            $(".js-invert-item").removeClass("inverted");
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parallax
    // *
    // *
    $("[data-parallax-speed]").each(function(){
        var scrollDistance = $(window).scrollTop(),
            elementParallaxOffset = parseInt($(this).css('transform').split(',')[5] ?? 0),
            elementDistance = Math.round($(this).offset().top - elementParallaxOffset),
            windowHeight = $(window).height(),
            parallaxValue = Math.round(((scrollDistance - elementDistance) + (windowHeight / 2))),
            parallaxValue = (parallaxValue * -1),
            parallaxSpeed = $(this).data("parallax-speed"),
            parallaxValue = parallaxValue * parallaxSpeed;

        // parallax
        if (!$(this).data("parallax-motion")) {
            $(this).css("transform", 'translateY(' + parallaxValue + 'px)');
            $(this).data("parallax-original", parallaxValue);
        }

        // turnin
        if ($(this).data("parallax-motion") == 'turnin') {
            if (parallaxValue >= 0 && parallaxValue <= 80) {
                if ($(this).parent().is(':nth-child(odd)')) {
                    $(this).css("transform", 'rotateY(' + parallaxValue + 'deg) rotate(' + ((parallaxValue / 8) * -1) + 'deg)  translateY(' + parallaxValue + 'px)');
                }else {
                    $(this).css("transform", 'rotateY(' + parallaxValue + 'deg) rotate(' + ((parallaxValue / 8) * 1) + 'deg)  translateY(' + parallaxValue + 'px)');
                }
                $(this).css("opacity", (1 - (parallaxValue / 100)));
            }

            if (parallaxValue < 0) {
                $(this).css("transform", 'none');
            }
        }

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * timeline highlight
    // *
    // *
    var windowHeight = $(window).height(),
        scrollDistance = $(window).scrollTop(),
        vpCenter =  scrollDistance + (windowHeight / 2);

    $(".js-timeline-section").each(function(){
        var start = $(this).offset().top,
            end = start + $(this).height(),
            sectionId = $(this).data("timeline-section");

        if (start <= vpCenter && end >= vpCenter) {
            highlightNavItem(sectionId);
        }
    });

});

var lastSectionId = '';

function highlightNavItem(sectionId) {
    var $activeElement = $('[data-timeline-nav-item="' + sectionId + '"]');

    $activeElement.addClass("active").siblings().removeClass("active");

    // if (lastSectionId != sectionId && sectionId) {
    //     lastSectionId = sectionId;
    //     asideNavScrollToActive($activeElement);
    // }
}

function asideNavScrollToActive($activeElement) {
    var $parent = $activeElement.parent(),
        parentHeight = $parent.outerHeight(),
        parentTop =  $parent.offset().top,
        elementTop = $activeElement.offset().top,
        scrollDistance = $parent.scrollTop(),
        distance = (elementTop + scrollDistance) - parentTop,
        distance = distance - (parentHeight / 2);

    $(".js-asidenav").animate({
        'scrollTop': distance + 'px'
    }, 100);
}

$(window).scroll(function() {
    clearTimeout($.data(this, 'scrollTimer'));
    $.data(this, 'scrollTimer', setTimeout(function() {
        asideNavScrollToActive($('[data-timeline-nav-item].active'));
        navScrollTo = true;
    }, 250));
});


$(".js-popup-inner").each(function() {
    $(this).on("scroll", function() {
        var scrollPos = $(this).scrollTop();
        var heroHeight = $(this).find(".hero").height();

        if (scrollPos > heroHeight) {
            $(".js-header").addClass("dark");
        } else {
            $(".js-header").removeClass("dark");
        }

        if (scrollPos < 200) {
            $(this).find(".hero__background").addClass("animateIn--active");
        } else {
            $(this).find(".hero__background").removeClass("animateIn--active");

        }
    });
});








